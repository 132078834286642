html, body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

#languageMenu {
  position: absolute;
  top: 0;
}

html[dir="LTR"] #languageMenu {
  right: 1em;
}

html[dir="RTL"] #languageMenu {
  left: 1em;
}

#header {
  position: absolute;
  top: 1em;
  left: 20%;
  right: 20%;
  width: 60%;
  text-align: center;
}

#banner {
  padding: 1em;
}

#subtitle {
  font-style: italic;
}

a {
  outline: none;
  text-decoration: none;
}

#clearDataPara {
  position: absolute;
  bottom: 0;
  margin: 1em;
}

#clearData {
  padding: 0 4px;
  margin: 5px 10px;
  font-size: medium;
}

#path {
  stroke: #ccc;
  stroke-width: 1;
  stroke-linecap: round;
  fill: none;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
  animation: dash .7s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.iconBack {
  fill: #fff;
}

.iconBorder {
  stroke: #aaa;
  stroke-width: 5;
  fill: rgba(0, 0, 0, .1);
}

a:focus > .iconBorder,
a:hover > .iconBorder {
  fill: rgba(255, 255, 255, .1);
}

a:focus > text,
a:hover > text {
  fill: #666;
}

.icon {
  animation: pop .5s cubic-bezier(.5,.5,.5,1.5) forwards;
  visibility: hidden;
}

.icon text {
  text-anchor: middle;
  font-size: 18pt;
}

#icon-puzzle {
  animation-delay: .0s;
}

#icon-maze {
  animation-delay: .1s;
}

#icon-bird {
  animation-delay: .2s;
}

#icon-turtle {
  animation-delay: .3s;
}

#icon-movie {
  animation-delay: .4s;
}

#icon-music {
  animation-delay: .5s;
}

#icon-pond-tutor {
  animation-delay: .6s;
}

#icon-pond-duck {
  animation-delay: .7s;
}

#back-puzzle {
  animation-delay: .3s;
}

#back-maze {
  animation-delay: .4s;
}

#back-bird {
  animation-delay: .5s;
}

#back-turtle {
  animation-delay: .6s;
}

#back-movie {
  animation-delay: .7s;
}

#back-music {
  animation-delay: .8s;
}

#back-pond-tutor {
  animation-delay: .9s;
}

#back-pond-duck {
  animation-delay: 1.0s;
}

@keyframes pop {
  from {
    transform: translate(150px, 50px) scale(0);
  }
  to {
    transform: translate(0, 0) scale(1);
    visibility: visible;
  }
}

.gaugeBack {
  stroke: #aaa;
  stroke-width: 6;
  fill: none;
  animation: pop .1s linear forwards;
  visibility: hidden;
}

.gaugeFront {
  stroke: #0b0;
  stroke-width: 8;
  fill: none;
}
