.h1Colaboradores {
    color: #2A2E36;
    text-align: center;
    font-family: 'Poppins';
    font-size: 90px;
    font-weight: 600;
    letter-spacing: -1.8px;
}

#colaboradores{
    margin-top: 150px;
}

.cards {
    display: flex;
    justify-content: space-evenly;
    margin-top: 130px;
    margin-bottom: 150px;
}

.cards img {
    display: flex;
    margin: auto;
}

.nome-colab {
    text-align: center;
    color: #2A2E36;
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 700;
    line-height: 132.5%; 
}

.descricao-colab{
    color: #525D73;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    width: 318px;
}