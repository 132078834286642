.btnCalculator{
    background-color: #5c677d;
    font-family: 'Poppins';
    font-size: 20px;
    text-decoration: none;
    border: none;
    color: white;
    cursor: pointer;
    text-align: center;
    padding: 10px;
    width: 150px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    

    border-radius: 8px;
    background: linear-gradient(90deg, #45BFFF 27.8%, #00A7FF 98.65%);
    box-shadow: 0px 24px 30px -16px rgba(45, 183, 255, 0.25);

}

.btnsCampanha {
    display: flex;
    justify-content: space-around;
}