.fill-height {
    height: calc(80vh - 114px);
  }

.buttonsWorkSpace{
    display: flex;
    align-items: center;
    padding: 40px 32px;
    justify-content: flex-end
}

.Btns{
    background-color: #5c677d;
    font-family: 'Poppins';
    font-size: 20px;
    text-decoration: none;
    border: none;
    color: white;
    cursor: pointer;
    text-align: center;
    padding: 10px;
    width: 150px;
    margin-right: 19px;

    border-radius: 8px;
    background: linear-gradient(90deg, #45BFFF 27.8%, #00A7FF 98.65%);
    box-shadow: 0px 24px 30px -16px rgba(45, 183, 255, 0.25);

}

.Btns:hover{
    background-color: #323742;
}


.buttonsWorkSpace > .Btns:not(:last-child) {
    margin-right: 30%;
    
}

.code{
    background-color: #10d5f8c7;
    width: 400px;
    max-height: 50px;
    overflow: auto;
    display: flex;
    margin-right: auto;
    border-radius: 5px;
    font-family: "Poppins";
    font-size: 15px;
    color: #FFFF;
    display: flex;
    padding: 15px;
    margin-left: 93px;
}

.divBtns {
    display: flex;
    width: 400px;
    justify-content: space-between;
    margin-left: 45px;
}