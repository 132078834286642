.p-contato {
    color: #594D43;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; 
    letter-spacing: 8.4px;
    margin-bottom: 8px;
    margin-top: 61px;
}

.p-email{
    color: #2A2E36;
    font-family: 'Poppins';
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 133.333% */
    letter-spacing: -0.6px;
    margin-top: 0;
}

.p-links {
    color: #3D475E;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
}

.p-links-navegacao {
    color: #3D475E;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    padding-left: 18px;

}

.direito li {
    color: #4A556D;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    list-style: none;
}

.links ul {
    padding-left: 0px;
    margin-bottom: 16px;
    margin-top: 33px;
}



#footer {
    display: flex;
    justify-content: space-between;
    padding-top: 95px;
    padding-bottom: 95px;
}

.direito {
    display: flex;
    align-items: center;
    margin-right: 171px;
}

.navegacao {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 40px;
}

.links {
    margin-bottom: 29px;
}

.esquerdo-contato{
    margin-left: 115px;
}

.p-footer {
    color: #4A556D;

    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px; /* 178.571% */
    letter-spacing: -0.14px;
    opacity: 0.7;
    padding-bottom: 40px;
}

a {
    text-decoration: none;
    color: #4A556D;
}