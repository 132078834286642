body {
  background-color: #fff;
  font-family: sans-serif;
  margin-top: 0;
}
h1 {
  font-weight: normal;
  font-size: 130%;
}
h1 a {
  text-decoration: none;
}
a:focus,
a:hover {
  color: #f00;
}
.farSide {
  text-align: right;
}
#header_cta>* {
  vertical-align: middle;
}
html[dir="RTL"] .farSide {
  text-align: left;
}
.level_number {
  border: solid 1px #ddd;
  border-radius: 20px;
  padding: 6px 12px;
  color: #888;
}
.level_dot {
  border: solid 1px #ddd;
	border-radius: 50%;
	width: 15px;
	height: 15px;
  display: inline-block;
}
.level_done, .level_dot:hover, .level_number:hover {
  background-color: #ddd;
  color: #000;
}
#containerCode {
  direction: ltr;
  background-color: #eee;
  padding: 5px;
  margin: 0 3ex;
  font-size: large;
  overflow: auto;
  max-height: 200px;
}

img[src$="help.png"] {
  height: 64px;
  width: 64px;
}

html[dir="RTL"] .mirrorImg {
  transform: scaleX(-1);
}

#languageMenu {
  margin: 16px 0;
}

xml {
  display: none;
}

/* Buttons */
button {
  margin: 5px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: large;
  background-color: #eee;
  color: #000;
}
button.primary {
  border: 1px solid #dd4b39;
  background-color: #dd4b39;
  color: #fff;
}
button.secondary {
  border: 1px solid #4d90fe;
  background-color: #4d90fe;
  color: #fff;
}
button.primary>img,
button.secondary>img {
  opacity: 1;
}
button>img {
  opacity: .6;
  vertical-align: text-bottom;
}
button:hover:not(:disabled)>img {
  opacity: 1;
}
button:active:not(:disabled) {
  border: 1px solid #888 !important;
}
button:hover:not(:disabled) {
  box-shadow: 2px 2px 5px #888;
}

/* Dialogs */
#dialog {
  visibility: hidden;
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
  position: absolute;
  border-radius: 8px;
  box-shadow: 5px 5px 5px #888;
  padding: 10px;
}
#dialogBorder {
  visibility: hidden;
  position: absolute;
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 6px;
  box-shadow: 5px 5px 5px #888;
}
#dialogShadow {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: .3
}
.dialogAnimate {
  transition-property: width height left top opacity;
  transition-duration: .2s;
  transition-timing-function: linear;
}
.dialogHiddenContent {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
#dialogHeader {
  height: 25px;
  margin: -10px -10px 15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #ddd;
  cursor: move;
}
#dialog button {
  min-width: 4em;
}
.readonly .blocklyMainBackground {
  stroke: none;
}
#galleryForm>header {
  font-size: larger;
}
#galleryForm>* {
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
}

/* Sprited icons. */
.icon21 {
  height: 21px;
  width: 21px;
  background-image: url(../images/icons.png);
}
.link {
  background-position: 0 0;
}
.camera {
  background-position: -21px 0;
}
.stop {
  background-position: -42px 0;
}
.run {
  background-position: -63px 0;
}
.gallery {
  background-position: -63px -21px;
}
.close {
  background-position: -42px -42px;
}
