

.container{
    display: flex;
    background: #F3F8FF;
}

.menu{
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    justify-content: space-around;
}

#logo {
    border: none;
    background-color: transparent;
    margin-right: 550px;
    cursor: pointer;
}


.btns {
    width: 250px;
    display: flex;
    justify-content: space-around;
}

#FreeMode, #Campaing{
    border: none;
    font-family: 'Poppins';
    color: #424D65;
    background-color: transparent;
    padding: 2px 2px; 
    cursor: pointer; 
}

#FreeMode:hover, #Campaing:hover {
    border-bottom: 2px solid #04A8FF; 
}


